<template>
  <div class="load-more relative" v-if="morepage">
    <div class="load-more-content" tcenter dpadding>
      <a v-if="!$loading" class="click-on-bottom sec-button button" @click="$emit('more')">
        <span class="icon"><iconic name="lock1a" /></span>
        <span>
          {{ $locale["object"]["nav"]["view-more-btn"] }}
          <span lowercase>{{ $locale["content"] }}</span>
        </span>
      </a>
      <a v-else-if="$loading"><iconic name="gloading" /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["morepage"],
};
</script>

<style lang="scss" scoped>
.load-more {
  .click-on-bottom {
    color: $primary-color;
  }
  .iconic {
    font-size: 180%;
    @include inconic();
  }
  .icon {
    font-size: 80%;
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $mpadding/2;
  }
}
</style>
